.pageheader {
  .page-logo {
    display: inline-block;
    background-color: $white;
    padding-top: 18px;
    padding-bottom: 18px;
    img {
      margin: -4px 14px -3px 0;
      width: 112px;
      height: 85px;
      &.english {
        width: 98px;
      }
    }
    a {
      border: 0;
    }

    @media(min-width: $screen-sm-min) {
      padding-top: 0;
    }

  }

  h1, .h1 {
    text-transform: uppercase;
    @media(min-width: $screen-sm-min){
      padding: 0 0 0 20px;
      color: $white;
    }
    @media(max-width: $screen-sm-min) {
      font-size: 18px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .navbar {
    margin-bottom: 15px;
  }

  .navbar-nav {
    li {
      &:before {
        content: '';
      }
    }
  }

  //add extra styling to all span elements in lists of navbar (bootstrap only adds these to links) to be able to use linked and non-linked items in lists with same positioning
  .navbar-nav
  > li
  > span {
    display: block;
    line-height: $line-height-computed;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    position: relative;
  }

  .navbar-default {
    border: 0;
    background-color: transparent;

    .nav-primary {
      padding: 16.5px 0;
      >li {
        >a,
        >a:hover,
        >a:focus {
          color: $brand-primary;
          background-color: transparent;
          text-transform: uppercase;
          text-decoration: none;
          border-bottom: 0;
          padding: 0 12px;
          span {
            display: inline-block;
            border-bottom: 2px solid transparent;
            white-space: nowrap;
          }
        }
        >a:hover,
        >a:focus {
          border-bottom: 0;
          span {
            border-color: $brand-primary;
          }
        }
        &.active {
          >a {
            span {
              border-color: $brand-primary;
            }
          }
        }
      }
    }
    .navbar-nav {
      .dropdown {
        .dropdown-toggle {
          position: relative;
          i {
            position: absolute;
            right: -6px;
            top: -1.5px;
          }
          @media(max-width: $screen-xs-max) {
            i {
              right: 15px;
            }
          }
        }

        .dropdown-menu {
          padding: 0;
          border-radius: 0;
          right: auto;
          left: 13px; // Items are not expected to change so we can use a left offset. If items become variable width this will no longer work
          top: 75%;
          li {
            height: 40px;
            margin-bottom: 0;
            border-bottom: 1px solid #ccc;
            line-height: 39px;
            &:hover,
            &:focus {
              background: $brand-tertiary;
            }
          }
          a,
          a:hover {
            border-bottom: 0;
            background: transparent;
            padding: 0px 20px;
            line-height: inherit;
            font-size: $fz-default;
            color: $default-text-color;
            font-family: $default-font-family;

          }

          @media(max-width: $screen-xs-max) {

            a {
              color: $white;
            }
            li {
              &:hover {
                a {
                  color: $brand-primary;
                }
              }
              &:first-child {
                border-top: 1px solid #ccc;
              }
              &:last-child {
                border-bottom: 0;
              }
            }

          }
        }
      }
    }
  }

  .nav-tertiary {
    font-size: $fz-small;
    padding-right: 20px;
    margin-top: 5px;
    >li {
      >a {
        color: $white;
        padding: 10px 10px;
        text-decoration: none;
        border-bottom: 0;
        span {
          border-bottom: 1px solid transparent;
        }
        &:hover,
        &:focus {
          color: $white;
          border-bottom: 0;
          span {
            border-color: $white;
          }
        }
      }
    }
  }

  .row.search {
    top: 60px;
    right: 0;
    .search-container {
      padding: 0;
      .input-group {
        display: flex;
        z-index: 3;

        .input-group-btn {
          width: auto;
          position: relative;
          right: auto;
        }
      }
      @media(min-width: $screen-sm-min) {
        padding-right: 10px;
      }
    }
    @media(min-width: $screen-sm-min) {
      position: absolute;
      right: 30px;
      top: 50px;
    }
  }

  .branding-container {

    a {
      border-bottom: 0;
    }
    &.small {
      h1, .h1 {
        font-size: $fz-defaultplus;
        line-height: 18px;
        font-family: $default-font-family;
      }
    }
    @media(min-width: $screen-sm-min) {
      position: absolute;
      top: 48px;

      &.small {
        top: 58px;
      }
    }
  }
  .search-container {
    display: none;
    padding: 0 20px;
    background-color: #fff;
    .input-group {
      height: 30px;
      >*,
      .btn {
        height: 28px;
      }
      .input-group-btn {
        position: absolute;
        right: 0;
        height: 28px;
      }
    }
    @media(min-width: $screen-sm-min) {
      display: block;
      padding: 0 10px;
      background-color: transparent;
      .input-group {
        display: block;
        width: 220px;
        height: 28px;
        box-sizing: content-box;
        input {
          width: 167px;
        }
        >*,
        .btn {
          font-size: $fz-default;
          //height: 20px;
        }
      }
    }

    input,
    button {
      //height: 20px;
      //line-height: 20px;
      font-size: $fz-small;
      text-overflow: clip;
    }

    input {
      font-family: $medium-font-family;
      color: $brand-primary;
      padding: 3px 10px;
      border: 0;
      box-shadow: 0;
      background-color: $white;
      width: 100%;
      @media(max-width: $screen-xs-max) {
        width: calc(100% - 53px);
      }
    }

    button {
      padding: 1px 0;
      width: 53px;
      display: block;
      text-overflow: initial;
    }
    .navbar-form {
      border: 0;
      box-shadow: none;
    }


  }

  &.showsearch {
    .branding-search-container{
      display: block;
    }
    .search-container {
      display: block;
    }
  }
  &.faculty {
    @media (max-width: $screen-xs-max) {
      button.btn-primary {
        background-color: $brand-primary;
        color: $white;
      }
    }
  }

  @media (max-width: $screen-xs-max){
    .navbar-default {
      background-color: $brand-primary;
      .toggle-search {
        position: absolute;
        color: white;
        right: 55px;
        //text-indent: -999px;
        top: 17px;
        width: 30px;
        //overflow: hidden;
        >span {
          font-size: 11px;
          position: absolute;
          top: 16px;
          left: -7px;
          width: 100%;
          text-align: center;
        }
        i {
          position: absolute;
          left: 0;
          top: 2px;
          text-indent: 0;
          &:before {
            position: absolute;
          }
        }
      }
      .navbar-toggle {
        margin-right: 10px;
        padding: 9px 10px 9px 0;
        margin-top: 8px;
        margin-bottom: 8px;
        border: 0;
        border-radius: 0;
        color: $white;
        text-decoration: none;
        >* {
          text-decoration: none;
        }
        >span {
          font-size: 11px;
          position: absolute;
          top: 25px;
          left: 10px;
        }

        .block {
          display: inline-block;
          padding-left: 10px;
        }
        .icon-bar {
          background-color: $white;
        }

        &:hover,
        &:focus {
          background-color: transparent;
        }
      }

      .nav-primary {
        margin-top: 4px;
        >li {
          border-bottom: 1px solid $white;
          margin-bottom: 4px;
          >a,
          >a:hover,
          >a:focus {
            color: $white;
          }
        }
      }

      .nav-tertiary {
        margin-left: -15px;
        li {
          margin-bottom: 0;
          a {
            padding: 2px 10px;
          }
        }
      }

      .navbar-collapse{
        height: auto !important;
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        -webkit-box-orient: vertical;
        border: 0;
        box-shadow: none;
        @media(max-width: $screen-xs-max){
          background: #ffffff;
        }
        .menu {
          display: none;
        }
        &.in {
          @media(max-width: $screen-xs-max){
            background: transparent;
          }

          .menu {
            display: block;
          }
        }

        .row {
          margin: 0;
          order: 1;
          + .row {
            order: 3;
            + .row {
              order: 2;
            }
          }
        }
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    .spacer {
      min-height: 110px;
    }
    .page-logo {
      img {
        margin: $padding-default 0 0;
        width: 118px;
        height: 90px;
        &.english {
          width: 104px;
        }
      }
    }
    .navbar-collapse {
      width: 83.33333%;
    }
    .nav-primary {
      font-size: $fz-medium;
      font-family: $medium-font-family;
      margin-left: -15px;
    }
     .toggle-search {
       display: none;
     }
  }

  @media (min-width: $screen-md-min) {  }

  @media (min-width: $screen-lg-min) {  }

  &.modal-layout {
    .page-logo {
      padding-bottom: 14px;
      @media(min-width: $screen-sm-min) {
        padding-bottom: 4px;
      }
    }
  }
}
